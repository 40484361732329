<template>
  <div class="row books">
    <div class="col-md-12 mt-2">
      <div class="card">
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="form-group row">
            <label for="" class="col-sm-2"> Search User <br /> </label>
            <div class="col-sm-7">
              <input
                type="text"
                v-model="filter"
                class="form-control"
                @keyup.enter="asyncFind"
                placeholder="email@domain.com"
              />
            </div>
            <div>
              <button
                v-if="!isLoading"
                class="btn btn-primary btn-sm"
                @click="asyncFind"
              >
                <i class="fa fa-search"></i>
              </button>
              <button v-else class="btn btn-primary btn-sm">
                <b-spinner class="align-middle"></b-spinner>
              </button>
            </div>
          </div>
          <div class="row justify-content-end mb-1">
            <button class="btn btn-sm btn-dark" @click="actionGetUsers">
              <i class="fa fa-refresh"></i>
            </button>

            <label for="" class="col-sm-auto">Count </label>
            <strong class="col-sm-auto">{{
              formatPriceBasic(totalRows)
            }}</strong>
          </div>

          <b-table
            sticky-header="500px"
            responsive="xl"
            :items="users"
            :busy="isLoading"
            :isLoading="isLoading"
            :fields="fields"
            hover
            show-empty
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}
            </template>
            <template #cell(user)="row">
              <router-link
                :to="{ path: '/customers/detail/' + row.item.email }"
              >
                {{ row.item.email }}
                <b-badge
                  v-if="
                    row.item.is_verified &&
                      row.item?.roles?.role_id == verifiedUser
                  "
                  variant="primary"
                  >verified</b-badge
                >
                <b-badge
                  v-if="
                    !row.item.is_verified ||
                      row.item?.roles?.role_id == unVerifiedUser
                  "
                  variant="warning"
                  >unverified</b-badge
                >
                <b-badge
                  v-if="row.item?.roles?.role_id == bannedUser"
                  variant="danger"
                  >banned</b-badge
                >
              </router-link>
              <b-button
                v-if="
                  !row.item.is_verified ||
                    (row.item?.roles?.role_id == unVerifiedUser &&
                      row.item.key_verify)
                "
                size="sm"
                variant="outline"
                @click="copyTextToClipboard(UrlVerify + row.item.key_verify)"
              >
                <i class="fa fa-copy"></i>
              </b-button>
            </template>
            <template #cell(first_name)="row">
              <div style="width: 200px">
                {{ row.item?.first_name ? row.item.first_name : '-' }}
              </div>
            </template>
            <template #cell(last_name)="row">
              <div style="width: 200px">
                {{ row.item?.last_name ? row.item.last_name : '-' }}
              </div>
            </template>
            <template #cell(created)="row">
              <div style="width: 250px">
                {{
                  row.item?.created ? formatDateString(row.item.created) : '-'
                }}
              </div>
            </template>
            <template #cell(last_login)="row">
              <div style="width: 250px">
                {{
                  row.item?.last_login &&
                  formatDate(row.item?.last_login) != '0001-01-01 00:00:00'
                    ? formatDateString(row.item?.last_login)
                    : '-'
                }}
              </div>
            </template>
            <template #cell(is_active)="row">
              <div style="width: 100px">
                <span v-if="row.item.is_active" class="badge badge-success">
                  Active
                </span>
                <span v-else class="badge badge-danger">
                  InActive
                </span>
              </div>
            </template>
            <template #cell(action)="row">
              <b-dropdown
                text="Action"
                split
                split-variant="outline-primary"
                variant="primary"
              >
                <b-dropdown-item
                  variant="primary"
                  @click="sendResetPassword(row.item.id, row.item.email)"
                >
                  <em class="fa fa-key mr-2 text-primary"></em>
                  Send Reset Password
                </b-dropdown-item>
                <b-dropdown-item
                  variant="primary"
                  @click="sendEmail(row.item.id, row.item.email)"
                  v-if="
                    !row.item.is_verified ||
                      row.item?.roles?.role_id == unVerifiedUser
                  "
                >
                  <em class="fa fa-envelope mr-2 text-primary"></em>
                  Send Verification
                </b-dropdown-item>
                <b-dropdown-item
                  variant="danger"
                  @click="onBannedUser(row.item.id, row.item.email)"
                  v-if="row.item?.roles?.role_id != bannedUser"
                >
                  <em class="fa fa-ban mr-2 text-primary-danger"></em>
                  Banned !!
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <svg class="heart" viewBox="0 0 32 29.6">
                  <path
                    d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
                  c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
                  />
                </svg>
                Loading ...
              </div>
            </template>
          </b-table>
        </div>
        <b-row
          v-if="totalRows / perPage > 1"
          class="justify-content-start ml-1 mt-2 pt-4"
        >
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import API from '../../plugins/http';
import constant from '../../store/constant';
import moment from 'moment';
const UrlVerify = process.env.VUE_APP_EBOOKS_URL + 'id/email-verification/';
export default {
  name: 'users',
  data() {
    return {
      UrlVerify,
      currentPage: 1,
      perPage: 100,
      filter: null,
      isLoadingSearch: false,
      items_search: [],
      fields: [
        { key: 'No', label: 'No' },
        { key: 'user', label: 'Email' },
        { key: 'first_name', label: 'First Name' },
        { key: 'last_name', label: 'Last Name' },
        { key: 'created', label: 'Register At' },
        { key: 'last_login', label: 'Last Login' },
        {
          key: 'is_active',
          label: 'Is Active',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'action',
          label: 'Action',
        },
      ],
      verifiedUser: constant.USER_ROLE.VERIFIED,
      unVerifiedUser: constant.USER_ROLE.UNVERIFIED,
      bannedUser: constant.USER_ROLE.BANNED,
    };
  },
  watch: {
    currentPage: function() {
      this.actionGetUsers();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Account | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.users.isLoading,
      isError: (state) => state.users.isError,
      totalRows: (state) => state.users.totalRows,
      users: (state) => state.users.items,
    }),
  },
  mounted() {
    this.actionGetUsers();
  },
  methods: {
    ...mapActions('users', [
      'fetchUsers',
      'searchUsers',
      'fetchUserById',
      'bannedUser',
      'resetPassword',
    ]),
    ...mapMutations('users', ['setItems', 'setRows']),
    actionGetUsers() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      if (this.filter) payload.email = this.filter;
      this.fetchUsers(payload);
    },
    formatPrice(value, currency) {
      if (value) {
        let formatter = null;
        if (currency == 'USD') {
          formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat('en-ID', {
            style: 'currency',
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return '0';
    },
    async asyncFind() {
      try {
        this.isLoadingSearch = true;
        let data = {
          page: 1,
          limit: this.perPage,
        };
        if (this.filter) data.email = this.filter;
        let info = await this.fetchUsers(data);
        console.log(info);
      } catch (error) {
        console.log(error);
      }
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    formatDateString(date) {
      return new Date(date).toString();
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },

    sendEmail(id, email) {
      this.$swal({
        title: 'Are you sure ?',
        text: `You will send email verification to ${email} !`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, send it!',
      }).then((result) => {
        if (result.isConfirmed) {
          API.get('users/resend-email-verification/' + id)
            .then((res) => {
              if (res.status == 200) {
                this.$swal(
                  'Sent!',
                  'Email verification has been successfully sent.',
                  'success',
                );
              }
            })
            .catch((err) => {
              this.$swal('Ops!', err.response.data.data.message, 'error');
            });
        }
      });
    },

    async onBannedUser(userId, email) {
      const VIOLATION_STATUS = Object.values(constant.VIOLATION_STATUS);
      const { value: formValues } = await this.$swal({
        title: `Banned ${email}`,
        html: `
            <div class="form-group">
              <select class="form-control" id="violation_status" type="text" placeholder="violation status" required>
                <option value>--Select Violation Status--</option>
                ${VIOLATION_STATUS.map(
                  (val) => `<option value="${val}">${val}</option>`,
                )}
              <select/>
            </div>
            <div class="form-group">
              <label for="expired_date">expired date</label>
              <input class="form-control" id="expired_date" type="date" required/>
            </div>
          `,
        focusConfirm: false,
        preConfirm: () => {
          return [
            document.getElementById('violation_status').value.trim(),
            document.getElementById('expired_date').value,
          ];
        },
      });

      if (!formValues) return;
      if (formValues[0] == '') {
        await this.$swal('please select violation status');
        return this.onBannedUser(userId, email);
      }
      this.bannedUser({
        user_id: userId,
        violation_status: formValues[0]?.toLowerCase(),
        expired_date: formValues[1],
      })
        .then((res) => {
          if (res.status == 201) {
            this.$swal(
              'Success!',
              'User has been banned successfully.',
              'success',
            );
          }
        })
        .catch((err) => {
          this.$swal('Ops!', err.response.data.data.message, 'error');
        });
    },

    sendResetPassword(id, email) {
      this.$swal({
        title: 'Are you sure ?',
        text: `You will send email reset password to ${email} !`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, send it!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.resetPassword({ id })
            .then((res) => {
              if (res.status == 200) {
                this.$swal({
                  title: 'Email reset password has been successfully sent !',
                  text: `click button to copy url`,
                  icon: 'success',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Copy',
                }).then((response) => {
                  if (response.isConfirmed) {
                    this.copyToClipboard(res.data.data);
                  }
                });
              }
            })
            .catch((err) => {
              if (err.response.status == 422) {
                this.$swal('Ops!', err.response.data.message, 'error');
              } else {
                if (err.response.data.data.url) {
                  this.$swal({
                    title: 'Reset password URL has been created.',
                    text: `click button to copy url`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Copy',
                  }).then((response) => {
                    if (response.isConfirmed) {
                      this.copyToClipboard(err.response.data.data.url);
                    }
                  });
                } else {
                  this.$swal('Ops!', err.response.data.data.message, 'error');
                }
              }
            });
        }
      });
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.$swal({
        toast: 'true',
        position: 'top-end',
        icon: 'success',
        title: 'Copied success',
        timer: 3000,
      });
    },
  },
};
</script>
