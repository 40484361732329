var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row books"
  }, [_c('div', {
    staticClass: "col-md-12 mt-2"
  }, [_c('div', {
    staticClass: "card"
  }, [_vm.isError ? _c('b-row', {
    staticClass: "justify-content-center mt-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "10"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": "",
      "dismissible": ""
    }
  }, [_vm._v(" Opps .. Something is wrong ")])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-sm-7"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter,
      expression: "filter"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "email@domain.com"
    },
    domProps: {
      "value": _vm.filter
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.asyncFind.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.filter = $event.target.value;
      }
    }
  })]), _c('div', [!_vm.isLoading ? _c('button', {
    staticClass: "btn btn-primary btn-sm",
    on: {
      "click": _vm.asyncFind
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  })]) : _c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('b-spinner', {
    staticClass: "align-middle"
  })], 1)])]), _c('div', {
    staticClass: "row justify-content-end mb-1"
  }, [_c('button', {
    staticClass: "btn btn-sm btn-dark",
    on: {
      "click": _vm.actionGetUsers
    }
  }, [_c('i', {
    staticClass: "fa fa-refresh"
  })]), _c('label', {
    staticClass: "col-sm-auto",
    attrs: {
      "for": ""
    }
  }, [_vm._v("Count ")]), _c('strong', {
    staticClass: "col-sm-auto"
  }, [_vm._v(_vm._s(_vm.formatPriceBasic(_vm.totalRows)))])]), _c('b-table', {
    attrs: {
      "sticky-header": "500px",
      "responsive": "xl",
      "items": _vm.users,
      "busy": _vm.isLoading,
      "isLoading": _vm.isLoading,
      "fields": _vm.fields,
      "hover": "",
      "show-empty": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (row.index + 1)) + " ")];
      }
    }, {
      key: "cell(user)",
      fn: function (row) {
        var _row$item, _row$item$roles, _row$item2, _row$item2$roles, _row$item3, _row$item3$roles, _row$item4, _row$item4$roles;
        return [_c('router-link', {
          attrs: {
            "to": {
              path: '/customers/detail/' + row.item.email
            }
          }
        }, [_vm._v(" " + _vm._s(row.item.email) + " "), row.item.is_verified && ((_row$item = row.item) === null || _row$item === void 0 ? void 0 : (_row$item$roles = _row$item.roles) === null || _row$item$roles === void 0 ? void 0 : _row$item$roles.role_id) == _vm.verifiedUser ? _c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("verified")]) : _vm._e(), !row.item.is_verified || ((_row$item2 = row.item) === null || _row$item2 === void 0 ? void 0 : (_row$item2$roles = _row$item2.roles) === null || _row$item2$roles === void 0 ? void 0 : _row$item2$roles.role_id) == _vm.unVerifiedUser ? _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("unverified")]) : _vm._e(), ((_row$item3 = row.item) === null || _row$item3 === void 0 ? void 0 : (_row$item3$roles = _row$item3.roles) === null || _row$item3$roles === void 0 ? void 0 : _row$item3$roles.role_id) == _vm.bannedUser ? _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("banned")]) : _vm._e()], 1), !row.item.is_verified || ((_row$item4 = row.item) === null || _row$item4 === void 0 ? void 0 : (_row$item4$roles = _row$item4.roles) === null || _row$item4$roles === void 0 ? void 0 : _row$item4$roles.role_id) == _vm.unVerifiedUser && row.item.key_verify ? _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline"
          },
          on: {
            "click": function ($event) {
              return _vm.copyTextToClipboard(_vm.UrlVerify + row.item.key_verify);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-copy"
        })]) : _vm._e()];
      }
    }, {
      key: "cell(first_name)",
      fn: function (row) {
        var _row$item5;
        return [_c('div', {
          staticStyle: {
            "width": "200px"
          }
        }, [_vm._v(" " + _vm._s((_row$item5 = row.item) !== null && _row$item5 !== void 0 && _row$item5.first_name ? row.item.first_name : '-') + " ")])];
      }
    }, {
      key: "cell(last_name)",
      fn: function (row) {
        var _row$item6;
        return [_c('div', {
          staticStyle: {
            "width": "200px"
          }
        }, [_vm._v(" " + _vm._s((_row$item6 = row.item) !== null && _row$item6 !== void 0 && _row$item6.last_name ? row.item.last_name : '-') + " ")])];
      }
    }, {
      key: "cell(created)",
      fn: function (row) {
        var _row$item7;
        return [_c('div', {
          staticStyle: {
            "width": "250px"
          }
        }, [_vm._v(" " + _vm._s((_row$item7 = row.item) !== null && _row$item7 !== void 0 && _row$item7.created ? _vm.formatDateString(row.item.created) : '-') + " ")])];
      }
    }, {
      key: "cell(last_login)",
      fn: function (row) {
        var _row$item8, _row$item9, _row$item10;
        return [_c('div', {
          staticStyle: {
            "width": "250px"
          }
        }, [_vm._v(" " + _vm._s((_row$item8 = row.item) !== null && _row$item8 !== void 0 && _row$item8.last_login && _vm.formatDate((_row$item9 = row.item) === null || _row$item9 === void 0 ? void 0 : _row$item9.last_login) != '0001-01-01 00:00:00' ? _vm.formatDateString((_row$item10 = row.item) === null || _row$item10 === void 0 ? void 0 : _row$item10.last_login) : '-') + " ")])];
      }
    }, {
      key: "cell(is_active)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "100px"
          }
        }, [row.item.is_active ? _c('span', {
          staticClass: "badge badge-success"
        }, [_vm._v(" Active ")]) : _c('span', {
          staticClass: "badge badge-danger"
        }, [_vm._v(" InActive ")])])];
      }
    }, {
      key: "cell(action)",
      fn: function (row) {
        var _row$item11, _row$item11$roles, _row$item12, _row$item12$roles;
        return [_c('b-dropdown', {
          attrs: {
            "text": "Action",
            "split": "",
            "split-variant": "outline-primary",
            "variant": "primary"
          }
        }, [_c('b-dropdown-item', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.sendResetPassword(row.item.id, row.item.email);
            }
          }
        }, [_c('em', {
          staticClass: "fa fa-key mr-2 text-primary"
        }), _vm._v(" Send Reset Password ")]), !row.item.is_verified || ((_row$item11 = row.item) === null || _row$item11 === void 0 ? void 0 : (_row$item11$roles = _row$item11.roles) === null || _row$item11$roles === void 0 ? void 0 : _row$item11$roles.role_id) == _vm.unVerifiedUser ? _c('b-dropdown-item', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.sendEmail(row.item.id, row.item.email);
            }
          }
        }, [_c('em', {
          staticClass: "fa fa-envelope mr-2 text-primary"
        }), _vm._v(" Send Verification ")]) : _vm._e(), ((_row$item12 = row.item) === null || _row$item12 === void 0 ? void 0 : (_row$item12$roles = _row$item12.roles) === null || _row$item12$roles === void 0 ? void 0 : _row$item12$roles.role_id) != _vm.bannedUser ? _c('b-dropdown-item', {
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": function ($event) {
              return _vm.onBannedUser(row.item.id, row.item.email);
            }
          }
        }, [_c('em', {
          staticClass: "fa fa-ban mr-2 text-primary-danger"
        }), _vm._v(" Banned !! ")]) : _vm._e()], 1)];
      }
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-dark my-2"
        }, [_c('svg', {
          staticClass: "heart",
          attrs: {
            "viewBox": "0 0 32 29.6"
          }
        }, [_c('path', {
          attrs: {
            "d": "M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2\n                c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
          }
        })]), _vm._v(" Loading ... ")])];
      },
      proxy: true
    }])
  })], 1), _vm.totalRows / _vm.perPage > 1 ? _c('b-row', {
    staticClass: "justify-content-start ml-1 mt-2 pt-4"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1) : _vm._e()], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "col-sm-2",
    attrs: {
      "for": ""
    }
  }, [_vm._v(" Search User "), _c('br')]);
}]

export { render, staticRenderFns }